.dfe-meta-content {
  .govuk-caption-m {
    display: inline;
  }

  dd {
    display: inline;
    margin-left: 0;
  }
}

.dfe-multiline-content {
  white-space: pre-line;
}
