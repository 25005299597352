@import '~govuk-frontend/dist/govuk/base';

.warning {
  align-items: center;
  display: flex;
}

.icon {
  margin-top: 0;
  min-height: 35px;
  min-width: 35px;
  position: relative;
}

.text {
  padding-left: govuk-spacing(3);
}

.error {
  border: 5px solid $govuk-error-colour;
  padding: govuk-spacing(4);

  .text {
    color: $govuk-error-colour;
  }

  .icon {
    background: $govuk-error-colour;
    border-color: $govuk-error-colour;
  }
}
