.recharts-legend-wrapper ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 1.1rem;
  justify-content: center;

  li {
    box-sizing: border-box;
    display: flex !important;
    justify-content: center;
    padding-right: 20px;
    text-align: left;
  }
}

.recharts-tooltip-wrapper {
  font-size: 1rem;
  max-width: 350px;
  padding: 0;

  .recharts-tooltip-item-list {
    white-space: normal;
  }

  .recharts-tooltip-item-list li {
    margin: 0;
    padding: 0;
  }

  .recharts-tooltip-item {
    color: #000 !important;
  }

  .recharts-tooltip-label,
  .recharts-tooltip-item-value,
  .recharts-tooltip-item-unit {
    font-weight: bold;
  }
}

@media print {
  .recharts-legend-wrapper ul {
    font-size: 0.7rem;
    margin-left: -30px !important;
  }

  .recharts-legend-item {
    .recharts-surface {
      width: 20px !important;
    }
  }
}
// workaround for recharts legend sharing the same color as chart lines
.recharts-legend-item-text {
  color: #000 !important;
}
