@import '~govuk-frontend/dist/govuk/base';

.previewPlaceholder {
  align-items: center;
  background-color: govuk-colour('light-grey');
  display: flex;
  font-size: 1.5rem;
  font-weight: $govuk-font-weight-bold;
  height: 300px;
  justify-content: center;
}
