.dfe-white-space--nowrap {
  white-space: nowrap;
}

.dfe-white-space--pre-wrap {
  white-space: pre-wrap;
}

.dfe-word-break--break-word {
  word-break: break-word;
}
