.dfe-float--right {
  float: right !important;
}

.dfe-vertical-align--bottom {
  vertical-align: bottom !important;
}

.dfe-position--relative {
  position: relative;
}
