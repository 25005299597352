@import '~govuk-frontend/dist/govuk/base';

.navigation {
  background: govuk-colour('light-grey');
  display: block;
  margin-bottom: govuk-spacing(8);
  margin-top: govuk-spacing(6);
}

.list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;

  li {
    margin-bottom: 0;
    padding: 1rem 0.5rem;
  }

  li a {
    font-weight: 700;
    padding: 1rem 1rem 0.7rem;
    text-decoration: none;
  }

  li a:hover {
    border-bottom: 0.3rem solid $govuk-link-hover-colour;
  }
}
