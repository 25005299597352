.govuk-form-group:last-child {
  margin-bottom: 0;
}

.govuk-input[disabled],
.govuk-textarea[disabled] {
  cursor: not-allowed;
}

// Hide the clear button on search inputs as they
// are not keyboard accessible, see EES-5210.
[type='search']::-webkit-search-cancel-button {
  appearance: none;
}

// EES-5308 - increase the radio border width on hover to make
// the hover style more noticeable.
// This has been suggested as a change to the design system,
// if it's adopted we can remove this override.
// https://github.com/alphagov/govuk-design-system-backlog/issues/59#issuecomment-2610085003
// stylelint-disable-next-line selector-max-compound-selectors
.govuk-radios--small
  .govuk-radios__item:hover
  .govuk-radios__input:not(:disabled)
  + .govuk-radios__label::before {
  border-width: 4px;
}
