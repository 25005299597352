@import '~govuk-frontend/dist/govuk/base';

.item {
  border-bottom: 1px solid govuk-colour('mid-grey');
  margin-bottom: govuk-spacing(4);
  padding-bottom: govuk-spacing(2);

  &:last-of-type {
    border-bottom: 0;
  }
}

.configurationInput {
  align-items: center;
  display: flex;
  margin-right: govuk-spacing(4);

  label {
    margin-right: govuk-spacing(2);
  }
}

.labelInput {
  composes: configurationInput;
  flex-grow: 1;
  min-width: 200px;
}

.colourInput {
  composes: configurationInput;

  input {
    width: 100px;
  }
}
