@import '~govuk-frontend/dist/govuk/base';

.tableContainer {
  margin: govuk-spacing(6) govuk-spacing(4);
  overflow: auto;

  &:focus-visible {
    outline: $govuk-focus-width solid $govuk-focus-colour;
  }
}
