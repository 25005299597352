@import '~govuk-frontend/dist/govuk/base';

.group {
  align-items: center;
  display: inline-flex;

  :not(:last-child) {
    margin-right: govuk-spacing(2);
  }
}
