@import '~govuk-frontend/dist/govuk/base';

.details {
  margin-bottom: govuk-spacing(2);

  :global(.govuk-details__text) {
    padding: govuk-spacing(2) 0 govuk-spacing(2) govuk-spacing(5);
  }

  .details :global(.govuk-details__text) {
    border-left: 0;
  }
}
