@import '~govuk-frontend/dist/govuk/base';

.buttonContainer {
  display: flex;
  flex-wrap: wrap;
}

.button {
  $button-margin: govuk-spacing(2);

  background: govuk-colour('light-grey');
  border: 1px solid govuk-colour('mid-grey');
  cursor: pointer;
  display: flex;
  flex-basis: calc(50% - #{$button-margin});
  flex-grow: 1;
  font-weight: bold;
  justify-content: space-between;
  margin: 0 $button-margin $button-margin 0;
  outline: none;
  padding: govuk-spacing(2);
  text-align: left;

  @include govuk-media-query($from: tablet) {
    flex-basis: calc(50% - #{$button-margin});
    max-width: calc(50% - #{$button-margin});
  }

  @include govuk-media-query($from: desktop) {
    flex-basis: calc(25% - #{$button-margin});
    max-width: calc(25% - #{$button-margin});
  }

  &:hover,
  &:focus,
  &[aria-pressed='true'] {
    background-color: govuk-colour('light-blue');
    border-color: transparent;
    box-shadow:
      0 0,
      0 4px $govuk-focus-text-colour;
    color: #fff;
  }

  &[aria-pressed='true'] {
    background-color: govuk-colour('blue');
  }

  .image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 6rem;
    object-fit: cover;
    width: 6rem;
  }
}
