@import '~govuk-frontend/dist/govuk/base';
@import '@admin/styles/variables';

// scroll-padding to ensure the sticky bar doesn't
// obscure keyboard focus.
:global(:root) {
  scroll-padding-top: 180px;

  @include govuk-media-query($from: tablet) {
    scroll-padding-top: 100px;
  }
}

.container {
  background: $govuk-brand-colour;
  margin: -#{govuk-spacing(6)} 0 govuk-spacing(6) -#{govuk-spacing(3)};
  max-width: $dfe-page-width-wide;
  padding: govuk-spacing(1) govuk-spacing(4);
  position: sticky;
  top: 0;
  width: calc(100% + #{govuk-spacing(6)});
  z-index: 1001;

  @include govuk-media-query($from: tablet) {
    margin-left: 0;
    width: 100%;
  }

  :global(.govuk-fieldset) {
    align-items: center;
    display: flex;
  }

  :global(.govuk-fieldset__legend) {
    color: govuk-colour('white');
    float: left;
    margin: 0 govuk-spacing(4) 1px 0;
  }

  :global(.govuk-label) {
    color: govuk-colour('white');
  }
}

.button {
  align-items: center;
  background: $govuk-brand-colour;
  border: 4px solid transparent;
  color: govuk-colour('white');
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  font-weight: $govuk-font-weight-bold;
  line-height: 1.25;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: left;
  width: 100%;

  &:focus,
  &:focus-visible {
    border-color: govuk-colour('yellow');
    outline: none;
  }
}
