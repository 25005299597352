@import '~govuk-frontend/dist/govuk/base';

.success {
  border-left-color: govuk-colour('turquoise');
}

.warning {
  border-left-color: govuk-colour('orange');
}

.error {
  border-left-color: govuk-colour('red');
}
