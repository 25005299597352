@import '~govuk-frontend/dist/govuk/base';

.grid {
  list-style: none;
  padding: 0;

  @include govuk-media-query($from: tablet) {
    display: flex;
    flex-wrap: wrap;
  }
}
