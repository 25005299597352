@import '~govuk-frontend/dist/govuk/base';

.map {
  background-color: govuk-colour('light-grey');
  transition: opacity 200ms;
  opacity: 1;

  path:focus {
    outline: none;
  }

  :global(.leaflet-control-zoom a:focus-visible) {
    background: $govuk-focus-colour;
    box-shadow: none;
    color: govuk-colour('black');
    outline: govuk-colour('black') solid $govuk-focus-width;
  }
}

.mapSpinner {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.mapLoading {
  position: relative;

  .map {
    opacity: 0.5;
  }
}

.tooltip {
  white-space: normal;
  width: 100%;
}

.tooltipContent {
  font-size: 0.9rem;
  margin-bottom: 0;
}

.legend {
  align-items: center;
  display: flex;
  line-height: 20px;
  margin-bottom: 0;
}

.legendIcon {
  border: 1px solid govuk-colour('black');
  forced-color-adjust: none;
  height: 20px;
  margin-right: govuk-spacing(1);
  width: 20px;
}
