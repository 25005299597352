@import '~govuk-frontend/dist/govuk/base';

.underlay {
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: flex;
  inset: 0;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: 1001;
}

.noUnderlayClick {
  cursor: default;
}

.dialog {
  align-items: center;
  background: #fff;
  cursor: initial;
  margin: 0 govuk-spacing(2);
  max-height: 98vh;
  max-width: map-get($govuk-breakpoints, 'desktop');
  min-width: 30vh;
  overflow-y: auto;
  padding: govuk-spacing(6);

  @include govuk-media-query($from: tablet) {
    margin: 0 govuk-spacing(4);
  }

  &:focus {
    outline: $govuk-focus-width solid $govuk-focus-colour;
    outline-offset: 0;
  }
}

.fullScreen {
  height: 100%;
  margin: 0;
  max-width: 100%;
  overflow-y: auto;
  padding: 0;
  width: 100%;

  &:focus {
    outline-offset: -$govuk-focus-width;
  }
}
