@import '~govuk-frontend/dist/govuk/base';

.container {
  position: relative;
}

.draggable {
  align-items: center;
  background: govuk-colour('white');
  border-bottom: 1px solid govuk-colour('mid-grey');
  display: flex;
  justify-content: space-between;
  min-height: 60px;
  padding: govuk-spacing(2) govuk-spacing(2) govuk-spacing(2) 0;

  &:hover {
    background: govuk-colour('light-grey');
  }

  &:focus {
    @include govuk-focused-text;
    z-index: 1;
  }

  &.notDragging:focus {
    background: govuk-colour('white');
  }
}

.isDragging {
  @include govuk-focused-text;
  z-index: 1;
}

.isDraggedOutside {
  opacity: 0.7;
}

.arrow {
  transform: rotate(90deg);
}

.controls {
  min-height: 38px; // to make correct height if arrow buttons not present.
  position: absolute;
  right: govuk-spacing(1);
  top: govuk-spacing(2);
}

.itemLabel {
  align-items: center;
  display: flex;
  padding: 0 90px 0 govuk-spacing(1); // Leave space for the controls.
  width: 100%;
  word-break: break-word;
}

.labelInner {
  flex-grow: 1;
}

.dragIcon {
  flex-shrink: 0;
  margin-right: govuk-spacing(2);
}
