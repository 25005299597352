@import '~govuk-frontend/dist/govuk/base';

.dragover {
  background: govuk-colour('light-grey');
}

// Override the default dnd style as it causes accordion sections within the dnd area to sometime open in the wrong direction.
.container [data-rbd-droppable-context-id] {
  overflow-anchor: auto;
}
