@import '~govuk-frontend/dist/govuk/base';
@import '@common/styles/variables';

.showMoreButton {
  line-height: 1;
}

.upDownArrow {
  transform: rotate(90deg);
}
