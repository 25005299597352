@import '~govuk-frontend/dist/govuk/base';
@import '@admin/styles/variables';

$border-width: 2px;
$locked-border-width: 4px;

.preview {
  margin-bottom: govuk-spacing(2);
  padding: govuk-spacing(2);
  position: relative;

  // stylelint-disable-next-line selector-no-qualifying-type
  img:not([alt]),
  img[alt=''] {
    border: 4px solid $govuk-error-colour;
    padding: 4px;
  }

  pre {
    white-space: normal;
  }
}

.readOnly {
  background: govuk-colour('light-grey');
  border: $border-width solid $govuk-border-colour;
  min-height: 2.2rem;
}

.editable {
  cursor: pointer;
}

.locked {
  border: $locked-border-width solid govuk-colour('orange');
  cursor: not-allowed;
}

.lockedMessage {
  background: govuk-colour('orange');
  bottom: -#{$locked-border-width};
  color: govuk-colour('white');
  font-weight: $govuk-font-weight-bold;
  padding: govuk-spacing(1) govuk-spacing(2);
  position: absolute;
  right: -#{$locked-border-width};
}
