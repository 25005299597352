@import '~govuk-frontend/dist/govuk/base';
@import '@common/styles/mixins/focus';

.url {
  background: govuk-colour('light-grey');
  border: 0;
  display: inline-block;
  flex-grow: 1;
  padding: govuk-spacing(2) govuk-spacing(4);
  text-overflow: ellipsis;
  user-select: all;
  white-space: pre-wrap;

  &:focus {
    @include focused-input;
  }
}
