@import '~govuk-frontend/dist/govuk/base';

.container {
  max-height: 70vh;
  overflow-y: auto;
  padding: 0 govuk-spacing(2);
  scrollbar-gutter: stable;
}

.selectContainer {
  max-width: 500px;
}
