@import '~govuk-frontend/dist/govuk/base';

.buttons {
  bottom: 0;
  background: govuk-colour('white');
  margin: 0 -#{govuk-spacing(6)};
  padding: govuk-spacing(2) govuk-spacing(6) govuk-spacing(6) govuk-spacing(6);
  position: sticky;
}

.modal {
  padding-bottom: 0;
  scroll-padding-bottom: 100px;
}
