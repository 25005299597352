@import '~govuk-frontend/dist/govuk/base';

.row {
  &:focus-visible {
    outline: $govuk-focus-colour solid $govuk-focus-width;
  }
}

.actionsColumn,
.statusColumn {
  width: 150px;
}
