@import '~govuk-frontend/dist/govuk/base';

.group {
  --horizontalSpacing: #{govuk-spacing(2)};
  --verticalSpacing: #{govuk-spacing(2)};

  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: govuk-spacing(6);
  column-gap: var(--horizontalSpacing);
  row-gap: var(--verticalSpacing);

  > * {
    margin-bottom: 0;
  }

  @include govuk-media-query($from: tablet) {
    flex-direction: row;
  }
}

td > .group {
  // Avoid centering within a table cell as this
  // is likely to be an 'Actions' column.
  align-items: start;
}

.horizontalSpacing--m {
  --horizontalSpacing: #{govuk-spacing(4)};
}

.verticalSpacing--m {
  --verticalSpacing: #{govuk-spacing(4)};
}

.horizontalSpacing--l {
  --horizontalSpacing: #{govuk-spacing(6)};
}

.verticalSpacing--l {
  --verticalSpacing: #{govuk-spacing(6)};
}
