@import '~govuk-frontend/dist/govuk/base';

.heading {
  display: flex;
  justify-content: space-between;
}

.list {
  max-height: 40vh;
  overflow: auto;
  padding: 0 0 govuk-spacing(2) 0;
}

.item {
  border-bottom: 1px solid govuk-colour('mid-grey');
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: govuk-spacing(2);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dragIcon {
  flex-shrink: 0;
  margin-left: 0.2rem;
}
