@import '~govuk-frontend/dist/govuk/base';

.button {
  background: $govuk-brand-colour;
  border: 2px solid transparent;
  color: govuk-colour('white');
  cursor: pointer;
  height: 40px;
  margin-bottom: 0;
  padding: 0;
  width: 40px;

  &:focus {
    box-shadow: inset 0 0 0 4px govuk-colour('black');
    outline: $govuk-focus-colour solid $govuk-focus-width;
  }
}

.icon {
  height: 100%;
}

.input {
  border-right-width: 0;

  &:focus {
    border-right-width: 2px;
    z-index: 1;
  }
}
