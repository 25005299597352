@import '~govuk-frontend/dist/govuk/base';

.container {
  @media print {
    display: none;
  }
}

.resultsLabel {
  background-color: govuk-colour('light-grey');
  font-size: 1rem;
  padding: govuk-spacing(2) govuk-spacing(1);
}

.resultHeader {
  display: block;
  width: 100%;
}

.resultLocation {
  font-size: 0.9rem;
}
