@import '~govuk-frontend/dist/govuk/base';

.container {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}

.spinner {
  $width-modifier: 0.15;

  animation: spin 2s linear infinite;
  border-color: govuk-colour('light-grey');
  border-radius: 50%;
  border-style: solid;
  border-top-color: govuk-colour('blue');
  display: block;
  margin-right: govuk-spacing(2);

  &--xl {
    border-width: #{80 * $width-modifier}px;
    height: 80px;
    width: 80px;
  }

  &--lg {
    border-width: #{60 * $width-modifier}px;
    height: 60px;
    width: 60px;
  }

  &--md {
    border-width: #{40 * $width-modifier}px;
    height: 40px;
    width: 40px;
  }

  &--sm {
    border-width: #{20 * $width-modifier}px;
    height: 20px;
    width: 20px;
  }
}

.inline {
  display: inline-block;
  margin-bottom: 0;
  margin-left: govuk-spacing(2);
}

.overlay {
  background-color: rgba(255, 255, 255, 0.75);
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 5000;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.text {
  margin-top: 0;
}

.noInlineFlex {
  display: flex;
}
