.dfe-flex {
  display: flex;
}

.dfe-flex-direction--column {
  flex-direction: column;
}

.dfe-flex-direction--row {
  flex-direction: row;
}

.dfe-flex-wrap {
  flex-wrap: wrap;
}

.dfe-justify-content--center {
  justify-content: center;
}

.dfe-justify-content--flex-start {
  justify-content: flex-start;
}

.dfe-justify-content--flex-end {
  justify-content: flex-end;
}

.dfe-justify-content--space-between {
  justify-content: space-between;
}

.dfe-align-items-start {
  align-items: flex-start;
}

.dfe-align-items--baseline {
  align-items: baseline;
}

.dfe-align-items--center {
  align-items: center;
}

.dfe-align-items-end {
  align-items: flex-end;
}

.dfe-flex-shrink--0 {
  flex-shrink: 0;
}

.dfe-flex-shrink--1 {
  flex-shrink: 1;
}

.dfe-flex-grow--0 {
  flex-grow: 0;
}

.dfe-flex-grow--1 {
  flex-grow: 1;
}

.dfe-align-self-end {
  align-self: end;
}
