@import '~govuk-frontend/dist/govuk/base';

.compact {
  :global(.govuk-summary-list__key),
  :global(.govuk-summary-list__value) {
    padding-bottom: 0;
    padding-top: govuk-spacing(1);
  }
}

.smallKey {
  :global(.govuk-summary-list__key) {
    width: 15%;
  }
}

// Vertical alignment is needed because we sometimes
// use <Tag> elements in the keys which can cause the
// alignment to be off.
.key {
  vertical-align: top;
}
